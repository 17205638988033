import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { DateSelectArg, EventClickArg } from '@fullcalendar/core';
import { useFormik } from 'formik';
import useAxios from '../../services/axios/useAxios';
import { UsersResponse } from '../../pages/users/Users';
import { GuestDto, GustRole } from '../../services/hooks/meeting/useMeeting.types';
import classNames from 'classnames';
import { GetRole, Role } from '../../services/utils/get-role.util';

interface EventModalProps {
  id?: string;
  readonly?: boolean;
  dateSelected?: DateSelectArg;
  eventSelected?: EventClickArg;
  handleOnSave?: (values: EventData) => void;
  handleOnCancel: () => void;
}

export interface EventData {
  id?: string;
  title: string;
  date: string;
  from: string;
  to: string;
  repetitive: boolean;
  daysOfWeek: string[];
  guests: GuestDto[];
}

const EventModal: FunctionComponent<EventModalProps> = ({
  dateSelected,
  eventSelected,
  handleOnSave,
  handleOnCancel,
  readonly,
}) => {
  const [guestSearch, setGuestSearch] = useState('');
  const [filteredGuestOptions, setFilteredGuestOptions] = useState<GuestDto[]>([]);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);
  const [inputActive, setInputActive] = useState(false);
  const axios = useAxios();

  const fetchData = useCallback(
    async (searchQuery: string) => {
      try {
        setLoading(true);
        const queryParams = new URLSearchParams();
        queryParams.append('limit', '10');
        queryParams.append('skip', '0');
        queryParams.append('search', searchQuery);
        queryParams.append('includeRoles', 'true');

        const response: UsersResponse = await axios.request({
          method: 'GET',
          url: `${process.env.REACT_APP_CHAT_API}/users?${queryParams.toString()}`,
        });

        const users = response?.users || [];
        setFilteredGuestOptions(
          users.map((user: any) => {
            return {
              userId: user.userId,
              email: user.email,
              role: GetRole(user.roles as Role[]),
            };
          }),
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [axios],
  );

  const data = eventSelected?.event || dateSelected;

  const formik = useFormik({
    initialValues: {
      id: eventSelected?.event.id,
      title: eventSelected?.event.title,
      date: dayjs(data?.startStr).format('YYYY-MM-DD'),
      from: dayjs(data?.startStr).format('HH:mm'),
      to: dayjs(data?.end).format('HH:mm'),
      repetitive: eventSelected?.event.extendedProps?.repetitive,
      daysOfWeek:
        (eventSelected?.event.extendedProps?.daysOfWeek?.map((day: number) =>
          day.toString(),
        ) as string[]) || [],
      guests: (Object.values(eventSelected?.event.extendedProps?.guests || {}) || []) as GuestDto[],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      date: Yup.string().required('Required'),
      from: Yup.string().required('Required'),
      to: Yup.string().required('Required'),
      repetitive: Yup.boolean(),
      daysOfWeek: Yup.array().of(Yup.string()),
      guests: Yup.array().of(Yup.object()).required('At least one guest is required'),
    }),
    onSubmit: (values: any) => {
      if (!handleOnSave) {
        return;
      }
      handleOnSave(values);
    },
  });

  useEffect(() => {
    if (guestSearch.length >= 3) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      setLoading(true);
      const timeout = setTimeout(() => {
        fetchData(guestSearch).then(() => {
          setLoading(false);
        });
      }, 300);
      setDebounceTimeout(timeout);
    } else {
      setFilteredGuestOptions([]);
    }
  }, [guestSearch, fetchData]);

  const handleDaysOfWeekChange = (day: string) => {
    if (formik.values.daysOfWeek.includes(day)) {
      formik.setFieldValue(
        'daysOfWeek',
        formik.values.daysOfWeek.filter((d) => d !== day),
      );
    } else {
      formik.setFieldValue('daysOfWeek', [...formik.values.daysOfWeek, day]);
    }
  };

  const handleGuestSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGuestSearch(e.target.value);
  };

  const handleAddGuest = (guest: GuestDto) => {
    if (guest && !formik.values.guests.some((g) => g.userId === guest.userId)) {
      formik.setFieldValue('guests', [...formik.values.guests, guest]);
      setGuestSearch('');
      setFilteredGuestOptions([]);
    }
  };

  const handleRemoveGuest = (guest: GuestDto) => {
    formik.setFieldValue(
      'guests',
      formik.values.guests.filter((g) => g.userId !== guest.userId),
    );
  };

  return (
    <div className='z-10 fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center'>
      <form
        className={classNames('bg-white p-5 rounded-lg shadow-lg w-full max-w-md', {
          'pointer-events-none': readonly,
        })}
        onSubmit={formik.handleSubmit}
        aria-disabled={readonly}
      >
        <h2 className='text-lg font-bold mb-4'>{readonly ? 'View Event' : 'Edit Event'}</h2>
        <div className='flex justify-between mb-4'>
          <div className='flex-1'>
            <div>Title</div>
            <div className='flex justify-between mb-4'>
              <input
                type='text'
                id='title'
                name='title'
                className='border border-gray-300 rounded p-2 w-full'
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              <div className='p-2'>
                {eventSelected?.event.extendedProps?.zoomId && (
                  <a
                    className='pointer-events-auto bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-700 hover:text-white'
                    href={`zoom/${eventSelected?.event.extendedProps?.zoomId}`}
                  >
                    Join
                  </a>
                )}
              </div>
            </div>

            {formik.touched.title && formik.errors.title && (
              <div className='text-red-500 text-sm'>{formik.errors.title}</div>
            )}
          </div>
        </div>
        {!readonly && (
          <>
            <div className='flex justify-between mb-4'>
              <div>
                <div>Date</div>
                <input
                  type='date'
                  id='date'
                  name='date'
                  className='border border-gray-300 rounded p-2'
                  onChange={formik.handleChange}
                  value={formik.values.date}
                />
                {formik.touched.date && formik.errors.date && (
                  <div className='text-red-500 text-sm'>{formik.errors.date}</div>
                )}
              </div>
              <div>
                <div>From</div>
                <input
                  type='time'
                  id='from'
                  name='from'
                  className='border border-gray-300 rounded p-2'
                  onChange={formik.handleChange}
                  value={formik.values.from}
                />
                {formik.touched.from && formik.errors.from && (
                  <div className='text-red-500 text-sm'>{formik.errors.from}</div>
                )}
              </div>
              <div>
                <div>To</div>
                <input
                  type='time'
                  id='to'
                  name='to'
                  className='border border-gray-300 rounded p-2'
                  onChange={formik.handleChange}
                  value={formik.values.to}
                />
                {formik.touched.to && formik.errors.to && (
                  <div className='text-red-500 text-sm'>{formik.errors.to}</div>
                )}
              </div>
            </div>
            <div className='flex justify-between mb-4'>
              <div className='flex items-center'>
                <label htmlFor='repetitive' className='mr-2'>
                  Repetitive
                </label>
                <label className='switch'>
                  <input
                    type='checkbox'
                    id='repetitive'
                    name='repetitive'
                    className='toggle-checkbox'
                    onChange={() => formik.setFieldValue('repetitive', !formik.values.repetitive)}
                    checked={formik.values.repetitive}
                  />
                  <span className='slider round'></span>
                </label>
              </div>
            </div>
            {formik.values.repetitive && (
              <div className='mb-4'>
                <div className='mb-2'>Days of Week</div>
                <div className='flex flex-wrap'>
                  {[
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                  ].map((day, index) => (
                    <div key={index} className='mr-2 mb-2'>
                      <button
                        type='button'
                        className={`px-3 py-1 rounded ${
                          formik.values.daysOfWeek.includes(index.toString())
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200'
                        }`}
                        onClick={() => handleDaysOfWeekChange(index.toString())}
                      >
                        {formik.values.daysOfWeek.includes(index.toString()) ? '✓ ' : '✗ '}
                        {day}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className='flex items-center mb-4'>
              <div className='mr-2'>
                <i className='fas fa-user'></i>
              </div>
              <div className='flex-grow relative'>
                <input
                  type='text'
                  id='guest'
                  name='guest'
                  className='border border-gray-300 rounded p-2 w-full'
                  placeholder='Search guest'
                  value={guestSearch}
                  onChange={handleGuestSearch}
                  onFocus={() => setInputActive(true)}
                  onBlur={() => setInputActive(false)}
                />
                {inputActive && guestSearch.length < 3 && (
                  <div className='absolute mt-1 bg-white border border-gray-300 rounded shadow-lg w-full z-10'>
                    <div className='p-2 text-gray-500'>Enter at least 3 characters to search</div>
                  </div>
                )}
                {loading && guestSearch.length >= 3 && (
                  <div className='absolute mt-1 bg-white border border-gray-300 rounded shadow-lg w-full z-10 flex justify-center items-center'>
                    <div className='w-8 h-8 border-4 border-blue-500 border-dashed rounded-full animate-spin'></div>
                  </div>
                )}
                {filteredGuestOptions.length > 0 && !loading && (
                  <div className='absolute mt-1 bg-white border border-gray-300 rounded shadow-lg w-full z-10'>
                    {filteredGuestOptions.map((guest, index) => {
                      const isAdded = formik.values.guests.some((g) => g.userId === guest.userId);
                      return (
                        <div
                          key={index}
                          className={`p-2 cursor-pointer hover:bg-gray-100 ${
                            isAdded ? 'bg-gray-200 pointer-events-none' : ''
                          }`}
                          onMouseDown={() => !isAdded && handleAddGuest(guest)}
                        >
                          {guest.email} ({guest.role})
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className='mb-4'>
          <div className='mb-2'>Guests</div>
          {formik.values.guests.length > 0 ? (
            <ul className='list-disc pl-5'>
              {formik.values.guests.map((guest, index) => (
                <li key={index} className='flex justify-between items-center mb-2'>
                  {guest.email} ({guest.role})
                  <button
                    type='button'
                    className='ml-2 text-red-500 hover:underline'
                    onClick={() => handleRemoveGuest(guest)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className='text-gray-500'>No guests added</p>
          )}
        </div>
        <div className='flex justify-end'>
          {handleOnSave && (
            <button
              type='submit'
              className='bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-700 disabled:opacity-50'
            >
              Save
            </button>
          )}
          <button
            type='button'
            className='bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 pointer-events-auto'
            onClick={handleOnCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EventModal;
