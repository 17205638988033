import React, { ReactElement, useEffect } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

interface GuardProps {
  children: ReactElement<any, any>;
}

export const PatientGuardedRoute: React.FC<GuardProps> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!keycloak) {
      return;
    }
    const isPatient = keycloak.hasRealmRole('patient');

    localStorage.setItem('lastPatientPath', location.pathname);
    localStorage.removeItem('lastAdminPath');
    localStorage.removeItem('lastManagerPath');
    localStorage.removeItem('lastPhysicianPath');
    keycloak.onReady = (authenticated) => {
      if (!authenticated && !isPatient) {
        navigate('/');
      }
    };
    if (keycloak.authenticated && !isPatient) {
      keycloak.logout();
      navigate('/');
    }
  }, [keycloak.authenticated]);
  return <>{children}</>;
};
