import React from 'react';

interface Props {
  progress: number;
  show: boolean;
}
export const UploadProgressModal = ({ progress, show }: Props) => {
  if (!show) return null;

  return (
    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center'>
      <div className='bg-white p-4 rounded-md text-center'>
        <h2 className='font-bold mb-2'>Uploading Video...</h2>
        <p className='mb-4'>Please do not close this page until the upload is complete.</p>
        <div className='bg-gray-200 rounded-full h-2.5 dark:bg-gray-700'>
          <div className='bg-blue-600 h-2.5 rounded-full' style={{ width: `${progress}%` }}></div>
        </div>
        <p className='mt-2'>{progress}%</p>
      </div>
    </div>
  );
};
