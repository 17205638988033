import React from 'react';
import * as faceapi from 'face-api.js';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientInitOptions } from '@react-keycloak/core/lib/types';
import { keycloakInstance } from './keycloack';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactModal.defaultStyles = {};
// PROVIDERS
import SettingsProvider from './providers/SettingsProvider';

// ENVIRONMENTS
import env from './env';
import AppRouter from './router/Routes/index';
import ReactModal from 'react-modal';

const App: React.FC = () => {
  const keycloakProviderInitConfig: AuthClientInitOptions = {
    // pkceMethod: "S256",
    // redirectUri: env.redirectUri,
    // onLoad: 'check-sso',
    // checkLoginIframe: false,
  };
  let container;
  return (
    <ReactKeycloakProvider authClient={keycloakInstance} initOptions={keycloakProviderInitConfig}>
      {/*<React.StrictMode>*/}
      <SettingsProvider>
        <AppRouter />
        <ToastContainer />
      </SettingsProvider>
      {/*</React.StrictMode>*/}
    </ReactKeycloakProvider>
  );
};

export default App;
