import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const Login: FC = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const authenticate = () => {
    keycloak.login();
  };

  useEffect(() => {
    if (!keycloak) {
      return;
    }
    keycloak.onReady = function (authenticated) {
      if (authenticated) {
        navigate('landing');
      }
    };
  }, [keycloak]);

  return (
    <div className='flex flex-col-reverse sm:flex-row absolute inset-0 sm:relative sm:h-screen sm:w-screen overflow-hidden bg-white p-4'>
      <div className='flex flex-col flex-1 sm:flex-none sm:w-1/2 sm:h-full justify-between sm:justify-center items-center px-0 sm:px-10 xl:px-24 py-8 sm:py-16'>
        <h1 className='text-2xl font-bold text-gray-800 mb-10'>Welcome to MHM Chat</h1>
        <div className='flex flex-col items-center justify-end sm:justify-center flex-1 w-full max-w-[432px] gap-3'>
          <button
            className='bg-green-500 text-white w-full sm:w-auto px-6 py-2 rounded-md shadow hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300'
            onClick={authenticate}
          >
            Access the system
          </button>
        </div>
      </div>
      <div
        className={`relative p-10 flex sm:flex-1 justify-center items-center sm:w-1/2 overflow-hidden bg-background-secondary rounded-3xl bg-gray-50`}
      >
        <img
          src={`/chat.png`}
          alt='login'
          className='h-auto w-full md:w-auto xl:w-full max-h-[200px] sm:max-h-[376px] xl:max-h-[500px] max-w-[200px] sm:max-w-[376px] xl:max-w-[500px]'
        />
      </div>
    </div>
  );
};

export default Login;
