import React from 'react';
import classNames from 'classnames';

import './dropdown.scss';

type Option = {
  label: string;
  value: any;
};

interface IDropdownProps {
  label: string;
  options: Option[];
  value: any;
  onChange: (val: any) => any;
  disabled?: boolean;
}

const Dropdown: React.FC<IDropdownProps> = ({ label, options, value, onChange, disabled }) => {
  return (
    <>
      <label htmlFor='botType' className='block mb-2 text-sm font-medium text-gray-700'>
        {label}
      </label>
      <select
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={classNames(
          `form-select appearance-none
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding bg-no-repeat
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`,
          {
            'bg-gray-200': disabled,
          },
        )}
      >
        {options.map((option, index) => (
          <option key={`${option.label}_${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default Dropdown;
