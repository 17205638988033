import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import useAxios from '../../../services/axios/useAxios';
import { SessionResponse } from '../../../services/hooks/session/useSession.types';
import { useNavigate } from 'react-router-dom';

// const ActionCell: React.FC<any> = ({ row }) => {
//   const axios = useAxios();
//   console.log(row);
//   const startProcessing = (target: string) => {
//     return async () => {
//       await axios?.request({
//         url: `${process.env.REACT_APP_CHAT_API}/sessions/${row.values.sessionId}/process`,
//         method: 'POST',
//         data: {
//           target,
//         },
//       });
//     };
//   };
//   return (
//     <>
//       <button
//         onClick={startProcessing('facial')}
//         className='text-green-500 hover:text-green-600 border p-2 border-green-600 rounded'
//       >
//         V
//       </button>
//       <button
//         onClick={startProcessing('text')}
//         className='text-green-500 hover:text-green-600 border p-2 border-green-600 rounded'
//       >
//         T
//       </button>
//       <button
//         onClick={startProcessing('audio')}
//         className='text-green-500 hover:text-green-600 border p-2 border-green-600 rounded'
//       >
//         A
//       </button>
//     </>
//   );
// };

export const ActionCell: React.FC<any> = ({ row }) => {
  const navigate = useNavigate();

  const handleViewSessions = useCallback(() => {
    navigate(`/sessions/${row.values.sessionId}`);
  }, [navigate, row.values.sessionId]);

  return (
    <button
      onClick={handleViewSessions}
      className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
    >
      Details
    </button>
  );
};
export default ActionCell;
