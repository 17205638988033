import React, { useCallback } from 'react';

interface PaginationProps {
  previousPage: () => void;
  nextPage: () => void;
  goToPage: (pageNumber: number) => void;
  totalPages: number;
  currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
 goToPage,
 previousPage,
 nextPage,
 currentPage,
 totalPages,
}) => {
  const renderPaginationItems = useCallback(() => {
    let items = [];

    items.push(
      <button
        key='prev'
        onClick={previousPage}
        disabled={currentPage === 1}
        className={`rounded-full h-8 w-8 text-sm font-medium text-blue-700 bg-white  border border-blue-300 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {'<'}
      </button>,
    );

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || i === currentPage || i === currentPage - 1 || i === currentPage + 1) {
        items.push(
          <button
            key={i}
            onClick={() => goToPage(i)}
            className={`rounded-full h-8 w-8 text-sm font-medium border border-blue-300 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 ${i === currentPage ? 'bg-blue-100' : 'bg-white'}`}
          >
            {i}
          </button>,
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        items.push(
          <span key={i} className='rounded-full text-center text-sm font-medium text-gray-700'>...</span>,
        );
      }
    }

    items.push(
      <button
        key='next'
        onClick={nextPage}
        disabled={currentPage === totalPages}
        className={`h-8 w-8 rounded-full text-sm font-medium text-blue-700 bg-white border border-blue-300 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {'>'}
      </button>,
    );

    return items;
  }, [currentPage, totalPages, previousPage, nextPage, goToPage]);

  return (
    <div className='flex items-center justify-center space-x-1 mt-4'>
      {renderPaginationItems()}
    </div>
  );
};

export default Pagination;
