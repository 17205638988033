import { useEffect, useState } from 'react';
import Tracker from '@openreplay/tracker';
import trackerProfiler from '@openreplay/tracker-profiler';
import trackerAssist from '@openreplay/tracker-assist';
import { useKeycloak } from '@react-keycloak/web';

let _tracker: Tracker;
if (process.env.REACT_APP_OR_PROJECT_KEY) {
  _tracker = new Tracker({
    projectKey: process.env.REACT_APP_OR_PROJECT_KEY,
    ingestPoint: process.env.REACT_APP_OR_INGEST_POINT || 'https://openreplay.primaface.com/ingest',
    __DISABLE_SECURE_MODE: true,
  });
}
export const useOpenReplay = () => {
  const { keycloak } = useKeycloak();
  const [tracker, setTracker] = useState<Tracker | undefined>();
  const [profiler, setProfiler] = useState<any>();

  useEffect(() => {
    if (!keycloak) {
      return;
    }

    if (!keycloak.authenticated) {
      return;
    }

    if (!_tracker) {
      return;
    }

    if (!_tracker.isActive()) {
      _tracker.setUserID(`${keycloak.tokenParsed?.name} (${keycloak.tokenParsed?.sub})`);
      _tracker.setMetadata('userId', keycloak.tokenParsed?.sub || '');
      _tracker.start();
      setTracker(_tracker);
      const profiler = _tracker.use(trackerProfiler());
      setProfiler(profiler);
      _tracker.use(
        trackerAssist({
          confirmText: 'Technical support request assistance',
        }),
      );
    }
  }, [keycloak.authenticated]);

  return [tracker, profiler];
};
