import { useCallback, useContext, useEffect } from 'react';

import { SettingsContext } from '../../../context';
import { SettingsType } from '../../../context/settings/settingsContext.types';

type SettingsHook = {
  settings: SettingsType;
  updateVoiceType: (voiceType: 'female' | 'male') => void;
  updateBotType: (botType: 1 | 2 | 3) => void;
  updateShowPatientText: (flag: boolean) => void;
  updateShowChatText: (flag: boolean) => void;
  updateUseChatVoice: (flag: boolean) => void;
};

const useSettings = (): SettingsHook => {
  const ctx = useContext(SettingsContext);

  if (!ctx) {
    throw new Error('No Provider.');
  }

  const { settings, setSettings } = ctx;

  useEffect(() => {
    setSettings(
      settings || {
        voiceType: 'female',
        botType: 3,
        showPatientText: true,
        showChatText: true,
        enableVoice: true,
      },
    );
  }, []);

  const updateVoiceType = useCallback((voiceType: 'female' | 'male') => {
    setSettings((prevSettings) => ({ ...prevSettings, voiceType }));
  }, []);

  const updateBotType = useCallback((botType: 1 | 2 | 3) => {
    setSettings((prevSettings) => ({ ...prevSettings, botType }));
  }, []);

  const updateShowPatientText = useCallback((patientText: boolean) => {
    setSettings((prevSettings) => ({ ...prevSettings, showPatientText: patientText }));
  }, []);

  const updateShowChatText = useCallback((chatText: boolean) => {
    setSettings((prevSettings) => ({ ...prevSettings, showChatText: chatText }));
  }, []);

  const updateUseChatVoice = useCallback((chatVoice: boolean) => {
    setSettings((prevSettings) => ({ ...prevSettings, enableVoice: chatVoice }));
  }, []);

  return {
    settings,
    updateVoiceType,
    updateBotType,
    updateShowPatientText,
    updateShowChatText,
    updateUseChatVoice,
  };
};

export default useSettings;
