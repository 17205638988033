import React, { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router';

import { AdminGuardedRoute } from '../Guards/AdminGuardedRoute';
import { PatientGuardedRoute } from '../Guards/PatientGuardedRoute';

import Sessions from '../../pages/sessions/Sessions';
import Users from '../../pages/users/Users';
import Login from '../../pages/Login';
import Landing from '../../pages/landing/Landing';
import NotFound from '../../pages/not-found/NotFound';
import SessionStarter from '../../pages/session-starter/SessionStarter';
import ChatSession from '../../pages/chat-session/ChatSession';

import { PatientLayout } from '../../components/layout/PatientLayout';
import { AdminLayout } from '../../components/layout/AdminLayout';
import { useOpenReplay } from '../../services/hooks/openreplay/use-openreplay';
import SessionDetailsPage from '../../pages/session-details/SessionDetails';
import ZoomMeeting from '../../pages/zoom-meeting/ZoomMeeting';
import SessionScheduler from '../../pages/session-scheduler/SessionScheduler';
import Calendar from '../../pages/calendar/Calendar';
import { DoctorGuardedRoute } from '../Guards/DoctorGuardedRoute';
import { DoctorLayout } from '../../components/layout/DoctorLayout';
import { ManagerGuardedRoute } from '../Guards/ManagerGuardedRoute';
import { ManagerLayout } from '../../components/layout/ManagerLayout';

const AppRouter: FC = () => {
  useOpenReplay();

  const patientRouter = (
    <Route
      path='patient'
      element={
        <PatientGuardedRoute>
          <PatientLayout>
            <Outlet />
          </PatientLayout>
        </PatientGuardedRoute>
      }
    >
      <Route path='' element={<SessionStarter />} />
      <Route path='chat' element={<ChatSession />} />
      <Route path='calendar' element={<Calendar />} />
    </Route>
  );

  const doctorRouter = (
    <Route
      path='physician'
      element={
        <DoctorGuardedRoute>
          <DoctorLayout>
            <Outlet />
          </DoctorLayout>
        </DoctorGuardedRoute>
      }
    >
      <Route path='calendar' element={<Calendar />} />
      <Route path='' element={<Navigate to='calendar' />} />
    </Route>
  );

  const managerRouter = (
    <Route
      path='manager'
      element={
        <ManagerGuardedRoute>
          <ManagerLayout>
            <Outlet />
          </ManagerLayout>
        </ManagerGuardedRoute>
      }
    >
      <Route path='zoom-scheduler' element={<SessionScheduler />} />
      <Route path='' element={<Navigate to='zoom-scheduler' />} />
    </Route>
  );

  const adminRouter = (
    <Route
      path='/'
      element={
        <AdminGuardedRoute>
          <AdminLayout>
            <Outlet />
          </AdminLayout>
        </AdminGuardedRoute>
      }
    >
      <Route path='dashboard' element={<></>} />
      <Route path='sessions' element={<Sessions />} />
      <Route path='sessions/:sessionId' element={<SessionDetailsPage />} />
      <Route path='users' element={<Users />} />
      <Route path='users/:userId/sessions' element={<Sessions />} />
    </Route>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/landing' element={<Landing />} />
        <Route path='/not-found' element={<NotFound />} />
        {adminRouter}
        {patientRouter}
        {doctorRouter}
        {managerRouter}
        <Route path='/:role/zoom/:id' element={<ZoomMeeting />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
