import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AudioPlayerProps } from './AudioPlayer.types';

import './AudioPlayer.scss';
import useSettings from '../../services/hooks/settings/useSettings';
import useDialogue from '../../services/hooks/dialogue/useDialogue';
import dayjs from 'dayjs';

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ audio, message, sessionId }) => {
  const [volume, setVolume] = useState<number>(1);
  let [speechStartedAt, setSpeechStartedAt] = useState<string>();

  const audioRef = useRef<HTMLAudioElement>(null);
  const { postDialog } = useDialogue();

  const {
    settings: { showPatientText },
    updateShowChatText,
  } = useSettings();

  const handleVolumeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const newVolume = parseFloat(e.target.value);
        if (audioRef.current) {
          audioRef.current.volume = newVolume;
        }
        setVolume(newVolume);
      } catch (e) {
        console.error(e);
      }
    },
    [audioRef],
  );

  useEffect(() => {
    if (audioRef.current && audio) {
      speechStartedAt = dayjs(new Date()).format();
      setSpeechStartedAt(speechStartedAt);

      audioRef.current?.addEventListener('ended', async () => {
        await postDialog({
          text: message || 'no_text',
          speaker: 'bot',
          sessionId,
          speechStartedAt,
          speechEndedAt: dayjs(new Date()).format(),
        });
      });

      audioRef.current.src = audio;
      audioRef?.current
        ?.play()
        .catch(async (e) => {
          console.log('audio errored text recieved');
          if (!showPatientText) {
            updateShowChatText(true);
          }
          // await postDialog({
          //   text: message || 'no_text',
          //   speaker: 'bot',
          //   sessionId,
          //   speechStartedAt: dayjs(new Date()).format(),
          //   speechEndedAt: dayjs(new Date()).format(),
          // });
        })
        .then(() => {
          console.log('audio started');
        });
    }
  }, [audio, showPatientText, speechStartedAt]);

  return (
    <div className='audio-player'>
      <audio ref={audioRef} src={audio}>
        Your browser does not support the audio element.
      </audio>
      {/*<span>Text to Speech Volume</span>*/}
      {/*<input*/}
      {/*  type='range'*/}
      {/*  min='0'*/}
      {/*  max='1'*/}
      {/*  step='0.01'*/}
      {/*  value={volume}*/}
      {/*  onChange={handleVolumeChange}*/}
      {/*/>*/}
    </div>
  );
};
