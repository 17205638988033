import { useCallback, useEffect, useRef } from 'react';

import { msgCallback, WebSocketHook } from './useWebsocket.types';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';

interface KeycloakUserprofile extends KeycloakProfile {
  attributes: {
    inflectionKey: [string];
  };
}
const useWebSocket = (
  onMessageCallback: msgCallback,
  sessionId: string | undefined,
  isLoading: boolean,
  botType: number,
  setIsLoading: (newState: boolean) => void,
): WebSocketHook => {
  const socketRef = useRef<WebSocket | null>(null);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    socketRef.current = new WebSocket(`${process.env.REACT_APP_CHAT_WS}/ws`) as WebSocket;
    // keycloak.login()

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [sessionId]);

  useEffect(() => {
    if (!sessionId || !socketRef.current) {
      return;
    }

    socketRef.current.onopen = async (): Promise<void> => {
      if (!socketRef.current) return;

      try {
        const user = (await keycloak.loadUserProfile()) as KeycloakUserprofile;

        // const previousConversationSummary: string = axios.request({
        //   method: 'GET',
        //   url: `${process.env.REACT_APP_CHAT_API}/sessions/latest`
        // });
        //
        // console.log(previousConversationSummary)

        socketRef.current.send(`${user.firstName} ${user.lastName}`);
        socketRef.current.send(String(botType));
        socketRef.current.send('0');
        socketRef.current.send(
          user.attributes?.inflectionKey?.length && user.attributes?.inflectionKey[0],
        );
        // socketRef.current?.send(previousConversationSummary);
      } catch (e) {
        console.error(e);
        // keycloak.logout();
      }
    };

    socketRef.current.onmessage = async (event: MessageEvent<any>): Promise<void> => {
      try {
        const { content } = JSON.parse(event.data);

        if (onMessageCallback) {
          if (
            content !== 'Enter Persona: ' &&
            content !== 'Bot type: ' &&
            content !== 'Prev convo: ' &&
            content !== 'User Inflection Key: '
          ) {
            await onMessageCallback(content);
            setIsLoading(false);
          }
        }
      } catch (e) {
        console.error('Invalid message format');
        setIsLoading(false);
      }
    };

    socketRef.current.onclose = () => {
      console.log('connection closed');
    };
  }, [onMessageCallback, sessionId, botType]);

  const sendMessage = useCallback((message: string) => {
    if (socketRef.current) {
      socketRef.current.send(message); // check
      setIsLoading(true);
    }
  }, []);

  return { sendMessage };
};

export default useWebSocket;
