import React, { PropsWithChildren, useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import Sidebar, { SidebarRoute } from '../sidebar/Sidebar';

export const DoctorLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { keycloak } = useKeycloak();

  const routes: SidebarRoute[] = [
    {
      separator: true,
      separatorLabel: 'Physician',
    },
    {
      label: 'Calendar',
      path: 'calendar',
    },
  ];

  const userInfo = useMemo(() => keycloak.tokenParsed, [keycloak.tokenParsed]);

  const navbar = (
    <nav className='bg-white border-b border-gray-200 fixed top-0 inset-x-0 z-10 h-14 flex items-center justify-between px-4'>
      <span className='text-lg font-semibold'>{userInfo?.name}</span>
    </nav>
  );

  return (
    <div className='flex absolute inset-0 lg:relative lg:h-screen lg:max-h-screen box-border'>
      <Sidebar routes={routes} color='cyan' />
      <div className='pt-[56px] flex-1 min-h-screen overflow-auto'>{children}</div>
    </div>
  );
};
