import * as React from 'react';
const StopMicrophone: React.FC<any> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={800}
    height={800}
    fill='none'
    viewBox='0 0 24 24'
    className='w-5 h-5'
    {...props}
  >
    <path
      stroke='#000'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M12 21h3m-3 0H9m3 0v-3m0 0c-4 0-6.5-2.686-6.5-6m6.5 6a7.9 7.9 0 0 0 2-.247M18.5 12c0 .701-.112 1.374-.326 2M3 3l18 18M9.163 5C9.661 3.87 10.521 3 12 3c3.172 0 3.5 4 3.5 6 0 .555-.025 1.265-.13 2'
    />
  </svg>
);
export default StopMicrophone;
