import Keycloak from 'keycloak-js';
import env from './env';

// Workaround because hot reloader throw an error that keycloak is initialised twice
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
let keycloakInstance: Keycloak = window.keycloakInstance;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!window.keycloakInstance) {
  keycloakInstance = new Keycloak({
    realm: env.keicloackRealm,
    url: env.keicloackUrl,
    clientId: env.keicloackClientId,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.keycloakInstance = keycloakInstance;
}

export { keycloakInstance };
