import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../components/spinner/Spinner';

export default function Landing() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!keycloak) {
      return;
    }
    if (!keycloak.authenticated) {
      setIsLoading(true);
    }
    keycloak.onReady = function (authenticated) {
      if (!authenticated) {
        navigate('/');
      }
      setIsLoading(false);
    };
  }, [keycloak]);

  useEffect(() => {
    if (!keycloak.authenticated) {
      return;
    }
    const isPatient = keycloak.hasRealmRole('patient');
    const isAdmin = keycloak.hasRealmRole('admin');
    const isPhysician = keycloak.hasRealmRole('physician');
    const isManager = keycloak.hasRealmRole('meetings-manager');

    const adminPath = localStorage.getItem('lastAdminPath') || '/dashboard';
    const patientPath = localStorage.getItem('lastPatientPath') || '/patient';
    const physicianPath = localStorage.getItem('lastPhysicianPath') || '/physician';
    const managerPath = localStorage.getItem('lastManagerPath') || '/manager';

    if (isAdmin) {
      navigate(adminPath);
      return;
    }

    if (isPatient) {
      navigate(patientPath);
      return;
    }

    if (isPhysician) {
      navigate(physicianPath);
      return;
    }

    if (isManager) {
      navigate(managerPath);
      return;
    }
  }, [keycloak.authenticated]);

  const takeActions = () => {};
  const handleSelectRole = (path: string) => {
    navigate(path);
    // Implement your logic to handle role selection here
  };

  const renderRoles = (
    <div className='flex flex-col h-screen w-screen justify-center items-center bg-gray-100'>
      <h1 className='text-2xl font-bold text-gray-800 mb-10'>
        Choose Your Role to Access the System
      </h1>
      <div className='flex space-x-4 mb-8'>
        <button
          className='bg-blue-500 text-white font-semibold py-4 px-6 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
          onClick={() => handleSelectRole('/dashboard')}
        >
          As Manager
        </button>
        <button
          className='bg-blue-500 text-white font-semibold py-4 px-6 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
          onClick={() => handleSelectRole('/patient')}
        >
          As Patient
        </button>
      </div>
      <button
        className='absolute top-4 right-4 text-blue-500 hover:text-blue-600'
        onClick={() => keycloak.logout()}
      >
        Log out
      </button>
    </div>
  );
  return <>{isLoading ? <Spinner /> : renderRoles}</>;
}
