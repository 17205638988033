import React, { useState, ReactNode } from 'react';

import { SettingsContext } from '../context';
import { SettingsType } from '../context/settings/settingsContext.types';

interface ISettingsProviderProps {
  children: ReactNode;
}

const SettingsProvider: React.FC<ISettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState<SettingsType>({
    botType: 1,
    showChatText: false,
    enableVoice: true,
    showPatientText: false,
    voiceType: 'female',
  });

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
