import { useCallback } from 'react';

import useAxios from '../../axios/useAxios';
import { CreateMeetingDto, MeetingDto, MeetingHook } from './useMeeting.types';

const useMeeting = (
  isLoading?: boolean,
  setIsLoading?: (newState: boolean) => void,
): MeetingHook => {
  const axios = useAxios();

  const postMeeting = useCallback(
    async (payload: CreateMeetingDto) => {
      try {
        setIsLoading && setIsLoading(true);
        const rs: MeetingDto = await axios.request({
          url: `${process.env.REACT_APP_CHAT_API}/meetings`,
          method: 'POST',
          data: payload,
        });
        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading && setIsLoading(false);
      }
    },
    [axios],
  );

  const patchMeeting = useCallback(
    async (id: string, payload: Partial<MeetingDto>) => {
      try {
        setIsLoading && setIsLoading(true);
        const rs: MeetingDto = await axios.request({
          url: `${process.env.REACT_APP_CHAT_API}/meetings/${id}`,
          method: 'PATCH',
          data: payload,
        });
        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading && setIsLoading(false);
      }
    },
    [axios],
  );

  const getMeetings = useCallback(
    async (userId?: string) => {
      try {
        setIsLoading && setIsLoading(true);
        const rs: MeetingDto[] = await axios.request({
          url: `${process.env.REACT_APP_CHAT_API}/meetings`,
          method: 'GET',
          params: { userIds: [userId] },
        });
        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading && setIsLoading(false);
      }
    },
    [axios],
  );
  return {
    postMeeting,
    getMeetings,
    patchMeeting,
  };
};

export default useMeeting;
