import { useCallback, useState } from 'react';

import useAxios from '../../axios/useAxios';

import { SessionDTO, SessionHook, SessionResponse } from './useSession.types';
import { useKeycloak } from '@react-keycloak/web';
import { UpdateSessions } from './update-sessions.interface';
import { ProcessSession } from './process-sessions.interface';
import {
  IResponseAudioFeaturesList,
  IResponseAudioList,
  IResponseBlinkDetectionList,
  IResponseFacialList,
  IResponseTextList,
} from './emotions.interface';

const useSession = (isLoading: boolean, setIsLoading: (newState: boolean) => void): SessionHook => {
  const axios = useAxios();
  const { keycloak } = useKeycloak();

  const [sessionId, setSessionId] = useState<string>('');

  const startSession = useCallback(async () => {
    if (sessionId) return;
    try {
      setIsLoading(true);
      const currentDate: Date = new Date();

      const session: SessionResponse = await axios?.request({
        url: `${process.env.REACT_APP_CHAT_API}/sessions`,
        method: 'POST',
        data: {
          start_timestamp: currentDate,
          end_timestamp: currentDate,
          userId: keycloak.subject,
        },
      });
      setSessionId(session.sessionId);
      return session.sessionId;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [axios, sessionId]);

  const updateSession = useCallback(
    async (payload: UpdateSessions) => {
      try {
        setIsLoading(true);

        await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${payload.sessionId}`,
          method: 'PATCH',
          data: payload,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, sessionId],
  );

  const processSession = useCallback(
    async (payload: ProcessSession) => {
      try {
        setIsLoading(true);

        await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${payload.sessionId}/process`,
          method: 'POST',
          data: {
            target: payload.target,
            userId: payload.userId,
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, sessionId],
  );

  const getSession = useCallback(
    async (id: string): Promise<SessionDTO | null> => {
      try {
        setIsLoading(true);

        const rs: SessionDTO = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}`,
          method: 'GET',
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getTextEmotions = useCallback(
    async (id: string): Promise<IResponseTextList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseTextList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/text-emotions`,
          method: 'GET',
          params: {
            limit: 1000,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getAudioEmotions = useCallback(
    async (id: string): Promise<IResponseAudioList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseAudioList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/audio-emotions`,
          method: 'GET',
          params: {
            limit: 1000,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getFacialEmotions = useCallback(
    async (id: string): Promise<IResponseFacialList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseFacialList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/face-emotions`,
          method: 'GET',
          params: {
            limit: 1000,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getAudioTextFeatures = useCallback(
    async (id: string): Promise<IResponseAudioFeaturesList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseAudioFeaturesList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/audio-text-features`,
          method: 'GET',
          params: {
            limit: 1,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getBlinkDetection = useCallback(
    async (id: string): Promise<IResponseBlinkDetectionList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseBlinkDetectionList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/blink-detection`,
          method: 'GET',
          params: {
            limit: 1,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const stopSession = useCallback(() => {
    setSessionId('');
  }, []);

  return {
    sessionId,
    startSession,
    stopSession,
    updateSession,
    getSession,
    processSession,
    getFacialEmotions,
    getAudioEmotions,
    getTextEmotions,
    getAudioTextFeatures,
    getBlinkDetection,
  };
};

export default useSession;
