import React, { useCallback, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import EventModal, { EventData } from '../../components/event-modal/eventModal';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';
import { useSearchParams } from 'react-router-dom';
import useAxios from '../../services/axios/useAxios';
import { checkTokenExpired } from '../../services/utils/check-token-expired.util';
import { toast } from 'react-toastify';
import useMeeting from '../../services/hooks/meeting/useMeeting';
import useZoom from '../../services/hooks/zoom/useZoom';
import { GustRole, MeetingDto } from '../../services/hooks/meeting/useMeeting.types';

dayjs.extend(duration);

const SessionScheduler = () => {
  const [selectedPerson, setSelectedPerson] = useState('all');
  const [zoomToken, setZoomToken] = useState<string>();
  const [user, setUser] = useState<KeycloakProfile>();
  const [meetings, setMeetings] = useState<EventInput[]>();
  const [showEventDialog, setShowEventDialog] = useState(false);
  const [selected, setSelected] = useState<DateSelectArg>();
  const [event, setEvent] = useState<EventClickArg>();
  const { keycloak } = useKeycloak();
  const [searchParams] = useSearchParams();
  const { postMeeting, getMeetings, patchMeeting } = useMeeting();
  const { refreshToken, createMeeting } = useZoom();
  const axios = useAxios();

  const updateToken = useCallback(
    async (token: string) => {
      try {
        const response = await refreshToken(token);

        setZoomToken(response?.access_token);
      } catch (e: any) {
        toast.error(`An error happened during refreshing token, ${e.toString()}`, {
          position: 'top-center',
        });
      }
    },
    [axios],
  );

  useEffect(() => {
    if (!keycloak?.authenticated) {
      return;
    }
    keycloak?.loadUserProfile().then(async (user: any) => {
      const m = await getMeetings();
      const g = getInitialEvents(m || []);
      setMeetings(g);
      const token = searchParams.get('zoomToken');
      if (!token) {
        return;
      }
      const isTokenExpired = checkTokenExpired(token);
      if (isTokenExpired) {
        return;
      }
      await updateToken(token);
    });
  }, [keycloak?.authenticated]);
  const handleDateSelect = (arg: DateSelectArg) => {
    if (!zoomToken) {
      toast.error(`Login in the zoom first`, {
        position: 'top-center',
      });
      return;
    }
    setSelected(arg);
    setEvent(undefined);
    setShowEventDialog(true);
  };
  const handleEventClick = (arg: EventClickArg) => {
    setEvent(arg);
    setSelected(undefined);
    setShowEventDialog(true);
  };
  const handleOnCancel = () => {
    setSelected(undefined);
    setShowEventDialog(false);
  };

  const createEvent = async (data: EventData) => {
    if (!selected || !zoomToken) {
      return;
    }

    const calendarApi = selected.view.calendar;

    calendarApi.unselect(); // clear date selection

    const [fh, fm] = data.from.split(':');
    const [eh, em] = data.to.split(':');

    const start = dayjs(selected.startStr)
      .set('hour', parseInt(fh))
      .set('minute', parseInt(fm))
      .set('second', 0)
      .set('millisecond', 0);

    const end = dayjs(selected.startStr)
      .set('hour', parseInt(eh))
      .set('minute', parseInt(em))
      .set('second', 0)
      .set('millisecond', 0);

    const zoomMeeting = await createMeeting(zoomToken);

    if (!zoomMeeting?.id) {
      return;
    }

    const meetingDto: Partial<MeetingDto> = {
      name: data.title,
      startsAt: start.toISOString(),
      endsAt: end.toISOString(),
      isRecurring: data.repetitive,
      guests: data.guests,
      zoomId: zoomMeeting.id.toString(),
    };

    if (data.repetitive) {
      meetingDto.recurrencePattern = {
        freq: 'weekly',
        interval: 1,
        dtstart: start.toISOString(),
        until: start.add(3, 'month').toISOString(),
        byweekday: data.daysOfWeek.map((day) => parseInt(day)),
      };
    }

    const meeting: MeetingDto | undefined = await postMeeting(meetingDto as MeetingDto);

    const e: EventInput = {
      id: meeting?.id,
      title: data.title,
      start: start.toISOString(),
      end: end.toISOString(),
      extendedProps: data.guests,
    };
    if (data.repetitive) {
      e.rrule = {
        freq: 'weekly',
        interval: 1,
        dtstart: start.toISOString(),
        until: start.add(3, 'month').toISOString(),
        byweekday: data.daysOfWeek.map((day) => parseInt(day)),
      };
      e.duration = dayjs.duration(end.diff(start)).format('HH:mm');
    }
    calendarApi.addEvent(e);
    setSelected(undefined);
    setShowEventDialog(false);
  };

  const editEvent = async (data: EventData) => {
    if (!event || !data?.id) {
      return;
    }

    const calendarApi = event.view.calendar;

    const [fh, fm] = data.from.split(':');
    const [eh, em] = data.to.split(':');

    const start = dayjs(data.date)
      .set('hour', parseInt(fh))
      .set('minute', parseInt(fm))
      .set('second', 0)
      .set('millisecond', 0);

    const end = dayjs(data.date)
      .set('hour', parseInt(eh))
      .set('minute', parseInt(em))
      .set('second', 0)
      .set('millisecond', 0);

    const meetingDto: Partial<MeetingDto> = {
      name: data.title,
      startsAt: start.toISOString(),
      endsAt: end.toISOString(),
      isRecurring: data.repetitive,
      guests: data.guests,
    };

    if (data.repetitive) {
      meetingDto.recurrencePattern = {
        freq: 'weekly',
        interval: 1,
        dtstart: start.toISOString(),
        until: start.add(3, 'month').toISOString(),
        byweekday: data.daysOfWeek.map((day) => parseInt(day)),
      };
    }

    await patchMeeting(data.id, meetingDto);

    const calendarEvent = calendarApi.getEventById(event.event.id)!;

    calendarEvent.setProp('title', data.title);
    calendarEvent.setProp('start', start.toISOString());
    calendarEvent.setProp('end', end.toISOString());
    calendarEvent.setProp('extendedProps', data.guests);
    if (data.repetitive) {
      calendarEvent.setProp('rrule', {
        freq: 'weekly',
        interval: 1,
        dtstart: start.toISOString(),
        until: start.add(3, 'month').toISOString(),
        byweekday: data.daysOfWeek.map((day) => parseInt(day)),
      });
      calendarEvent.setProp('duration', dayjs.duration(end.diff(start)).format('HH:mm'));
    }
    // calendarApi.addEvent(e);

    setEvent(undefined);
    setShowEventDialog(false);
  };

  const handleOnSave = async (data: EventData) => {
    try {
      if (selected) {
        await createEvent(data);
      }
      if (event) {
        await editEvent(data);
      }
    } catch (e: any) {
      toast.error(`An error happened during event creation, ${e.toString()}`, {
        position: 'top-center',
      });
    }
  };

  const getInitialEvents = useCallback((m: MeetingDto[]): EventInput[] => {
    if (!m) {
      return [];
    }
    return m.map((meeting) => {
      const event: EventInput = {
        id: meeting.id,
        title: meeting.name,
        start: meeting.startsAt,
        end: meeting.endsAt,
        extendedProps: {
          guests: meeting.guests,
          repetitive: meeting.isRecurring,
          daysOfWeek: meeting.recurrencePattern?.byweekday || [],
        },
      };
      if (meeting.isRecurring) {
        event.rrule = {
          freq: meeting.recurrencePattern?.freq,
          interval: meeting.recurrencePattern?.interval,
          dtstart: meeting.recurrencePattern?.dtstart,
          until: meeting.recurrencePattern?.until,
          byweekday: meeting.recurrencePattern?.byweekday,
        };
        event.duration = dayjs
          .duration(dayjs(meeting.endsAt).diff(dayjs(meeting.startsAt)))
          .format('HH:mm');
      }
      return event;
    });
  }, []);

  return (
    <div className=''>
      <div className='flex-1 p-4'>
        <div className='flex justify-end items-center mb-4'>
          <button
            className='bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-700 disabled:opacity-50'
            onClick={() => {
              location.href = process.env.REACT_APP_ZOOM_AUTH_URL!;
            }}
            disabled={!!zoomToken}
          >
            Login to ZOOM
          </button>
          <select
            className='border border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
            onChange={(e) => setSelectedPerson(e.target.value)}
          >
            <option value='all'>All</option>
            <option value='John'>John</option>
            <option value='Sarah'>Sarah</option>
            <option value='Team'>Team</option>
            <option value='Client'>Client</option>
          </select>
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, rrulePlugin]}
          initialView='timeGridWeek'
          select={handleDateSelect}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          eventClick={handleEventClick}
          editable={false}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          events={meetings}
        />
      </div>
      {showEventDialog && (
        <EventModal
          dateSelected={selected}
          eventSelected={event}
          handleOnSave={handleOnSave}
          handleOnCancel={handleOnCancel}
        />
      )}
    </div>
  );
};

export default SessionScheduler;
