import { useEffect } from 'react';

export const useConfirmOnPageExit = (shouldConfirm: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (!shouldConfirm) return;
      const message = 'Are you sure you want to leave? Any unsaved changes will be lost.';
      e.returnValue = message; // Gecko + IE
      return message; // Gecko + Webkit, Safari, Chrome etc.
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldConfirm]);
};
