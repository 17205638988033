export const checkMicrophonePermissions = async () => {
  let retval = false;

  await navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((stream: MediaStream) => (retval = true))
    .catch((err: any) => (retval = false));

  return retval;
};
