export type MeetingHook = {
  postMeeting: (payload: CreateMeetingDto) => Promise<MeetingDto | undefined>;
  patchMeeting: (id: string, payload: Partial<MeetingDto>) => Promise<MeetingDto | undefined>;
  getMeetings: (userId?: string) => Promise<MeetingDto[] | undefined>;
};

export enum RecurrenceFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum GustRole {
  ORGANISER = 'organiser',
  PATIENT = 'patient',
  PHYSICIAN = 'physician',
  NONE = 'none',
}

export interface GuestDto {
  id?: string;
  userId: string;
  email: string;
  role: GustRole;
  meetingId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface MeetingDto {
  id: string;
  name: string;
  guests: GuestDto[];
  zoomId: string;
  startsAt: string;
  endsAt: string;
  isRecurring?: boolean;
  recurrencePattern?: RecurrencePatternDto;
  createdAt: Date;
  updatedAt: Date;
}

export interface RecurrencePatternDto {
  freq: string;
  interval: number;
  dtstart: string;
  until: string;
  byweekday: number[];
}

export type CreateMeetingDto = Pick<
  MeetingDto,
  'name' | 'zoomId' | 'guests' | 'startsAt' | 'endsAt' | 'recurrencePattern' | 'isRecurring'
>;
