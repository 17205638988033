import { useCallback } from 'react';

import useAxios from '../../axios/useAxios';
import {
  GenerateSignatureDto,
  RefreshTokenResponse,
  SignatureResponseDto,
  ZoomHook,
  ZoomMeetingDto,
} from './useZoom.types';

const useZoom = (isLoading?: boolean, setIsLoading?: (newState: boolean) => void): ZoomHook => {
  const axios = useAxios();

  const refreshToken = useCallback(
    async (refreshToken: string) => {
      try {
        setIsLoading && setIsLoading(true);
        const rs: RefreshTokenResponse = await axios.request({
          url: `${process.env.REACT_APP_CHAT_API}/zoom/refresh-token`,
          method: 'POST',
          params: {
            refreshToken,
          },
        });
        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading && setIsLoading(false);
      }
    },
    [axios],
  );

  const createMeeting = useCallback(
    async (token: string) => {
      try {
        setIsLoading && setIsLoading(true);
        const rs: ZoomMeetingDto = await axios.request({
          url: `${process.env.REACT_APP_CHAT_API}/zoom/create-meeting`,
          method: 'POST',
          params: {
            token,
          },
        });
        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading && setIsLoading(false);
      }
    },
    [axios],
  );

  const generateSignature = useCallback(
    async (payload: GenerateSignatureDto) => {
      try {
        setIsLoading && setIsLoading(true);
        const rs: SignatureResponseDto = await axios.request({
          url: `${process.env.REACT_APP_CHAT_API}/zoom/generate-signature`,
          method: 'POST',
          data: payload,
        });
        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading && setIsLoading(false);
      }
    },
    [axios],
  );
  return {
    refreshToken,
    createMeeting,
    generateSignature,
  };
};

export default useZoom;
