import { GustRole } from '../hooks/meeting/useMeeting.types';

export interface Role {
  id: string;
  name: string;
  description: string;
  composite: boolean;
  clientRole: boolean;
  containerId: string;
}
export const GetRole = function (role: Role[]): GustRole {
  for (let i = 0; i < role.length; i++) {
    if (role[i].name === GustRole.PHYSICIAN) {
      return GustRole.PHYSICIAN;
    }
  }

  return GustRole.PATIENT;
};
