import React, { FC, PropsWithChildren } from 'react';

import Sidebar, { SidebarRoute } from '../sidebar/Sidebar';

export const AdminLayout: FC<PropsWithChildren> = ({ children }) => {
  const routes: SidebarRoute[] = [
    {
      separator: true,
      separatorLabel: 'ADMIN',
    },
    {
      label: 'Dashboard',
      path: '/dashboard',
    },
    {
      label: 'Users',
      path: '/users',
    },
    {
      label: 'Sessions',
      path: '/sessions',
    },
  ];
  return (
    <div className='flex absolute inset-0 lg:relative lg:h-screen lg:max-h-screen box-border'>
      <Sidebar routes={routes} />
      <div className='flex-1 min-h-screen overflow-auto'>{children}</div>
    </div>
  );
};
