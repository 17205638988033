import React, { FC, SVGProps } from 'react';
export const Refresh: FC<SVGProps<any>> = ({ fill = '#fff', height = '24px', width = '24px' }) => {
  return (
    <svg
      fill={fill}
      height={height}
      width={width}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      enableBackground='new 0 0 64 64'
    >
      <g id='Refresh'>
        <path
          d='M34.8769989,18.0142002l3.0848999-2.6489c0.4190025-0.3604002,0.4668007-0.9913006,0.1074028-1.4102001
		c-0.360302-0.4198999-0.9921036-0.4658003-1.4101028-0.1073999L31.5049,18.2735004
		c-0.4190006,0.3603001-0.4668007,0.9911995-0.1074009,1.410099l4.4258003,5.1543007
		c0.1982002,0.2304993,0.4775009,0.3486004,0.7587013,0.3486004c0.2314987,0,0.4628983-0.0790997,0.6514206-0.2412014
		c0.4190025-0.3603001,0.4667778-0.9911995,0.1073799-1.410099l-3.0873795-3.5955009
		c7.1364784,0.8006001,12.7035789,6.8648014,12.7035789,14.2117023c0,7.8905983-6.4179001,14.309597-14.307579,14.309597
		c-7.8906212,0-14.3095207-6.4189987-14.3095207-14.309597c0-0.5527-0.4473-1-1-1c-0.5528011,0-1,0.4473-1,1
		c0,8.9931984,7.3163986,16.309597,16.3095207,16.309597c8.9921799,0,16.307579-7.3163986,16.307579-16.309597
		C48.9570007,25.9167004,42.8164215,19.1063004,34.8769989,18.0142002z'
        />
        <path
          d='M32,0c-17.6730995,0-32,14.3268995-32,32s14.3268995,32,32,32s32-14.3269005,32-32S49.6730995,0,32,0z M32,62
		c-16.5419998,0-30-13.457901-30-30c0-16.5419998,13.4579992-30,30-30c16.542099,0,30,13.4580002,30,30
		C62,48.542099,48.542099,62,32,62z'
        />
      </g>
    </svg>
  );
};
