import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import useSettings from '../../services/hooks/settings/useSettings';
import { Dropdown, Toggle } from '../../components/input';
import { useNavigate } from 'react-router-dom';

interface SessionStarterProps {
  isAdmin?: boolean;
}

const SessionStarter: React.FC<SessionStarterProps> = ({ isAdmin = false }) => {
  const navigate = useNavigate();

  const {
    settings,
    updateShowChatText,
    updateShowPatientText,
    updateVoiceType,
    updateBotType,
    updateUseChatVoice,
  } = useSettings();

  const voiceOptions = useMemo(
    () => [
      {
        label: 'Male',
        value: 'male',
      },
      {
        label: 'Female',
        value: 'female',
      },
    ],
    [],
  );

  const botOptions = useMemo(
    () => [
      {
        label: '1',
        value: 1,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '3',
        value: 3,
      },
      {
        label: '4',
        value: 4,
      },
      {
        label: '5',
        value: 5,
      },
    ],
    [],
  );

  const { enableVoice, voiceType, botType, showPatientText, showChatText } = settings;

  const startSession = useCallback(() => {
    navigate(isAdmin ? '/chat' : 'chat');
  }, [isAdmin]);

  const isNotValid = !enableVoice && !showChatText;
  return (
    <div className='flex flex-col items-center justify-center h-full bg-gray-100'>
      <div
        className={classNames('bg-white p-8 rounded-lg shadow-lg text-center min-w-[500px]', {
          'border border-red-500 bg-red-100': isNotValid,
        })}
      >
        <h1 className='text-xl font-bold mb-4'>Start Session</h1>

        <div className='flex items-center mb-4'>
          <Toggle label='Chat Voice' value={enableVoice} onChange={updateUseChatVoice} />
        </div>
        <div className='flex items-center mb-4'>
          <Toggle label='Chat Text' value={showChatText} onChange={updateShowChatText} />
        </div>
        <div className='flex items-center mb-4'>
          <Toggle label='Patient Text' value={showPatientText} onChange={updateShowPatientText} />
        </div>

        {isNotValid ? (
          <div className='absolute top-16 border border-red-500 text-red-500 bg-red-100 p-3 rounded-md w-[96vw]  left-[2vw]'>
            <p>Need to enable either Chat Voice or Chat Text option.</p>
          </div>
        ) : null}

        <div className='mb-4'>
          <Dropdown
            label='Bot Type'
            options={botOptions}
            value={botType}
            onChange={updateBotType}
          />
        </div>

        <div className='mb-4'>
          <Dropdown
            label='Voice Type'
            disabled={!enableVoice}
            options={voiceOptions}
            value={enableVoice ? voiceType : undefined}
            onChange={updateVoiceType}
          />
        </div>
        <button
          disabled={isNotValid}
          className={classNames(
            'w-full px-4 py-2 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:bg-blue-700',
            {
              'bg-blue-600': !isNotValid,
              'pointer-events-none bg-gray-300 text-black': isNotValid,
            },
          )}
          onClick={startSession}
        >
          Start Session
        </button>
      </div>
    </div>
  );
};

export default SessionStarter;
