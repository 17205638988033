import React, { useCallback, useRef, useState } from 'react';
import useAxios from '../../axios/useAxios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

type VideoRecordHook = {
  uploadProgress: number;
  showUploadModal: boolean;
  recording: boolean;
  handleStartRecording: (sessionId: string | undefined) => Promise<void>;
  stopRecording: () => void;
};

const useVideoRecord = (
  videoRef: React.MutableRefObject<HTMLVideoElement | null>,
  mediaRecorderRef: React.MutableRefObject<MediaRecorder | null>,
  onUploaded?: () => void,
): VideoRecordHook => {
  const [recording, setRecording] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const recordedChunks = useRef<Blob>();

  const axios = useAxios();

  const handleStartRecording = useCallback(
    async (sessionId: string | undefined) => {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        // mimeType: 'video/webm; codecs=vp8',
      });

      mediaRecorderRef.current.ondataavailable = handleDataAvailable;

      mediaRecorderRef.current.onstop = () => {
        if (videoRef.current && videoRef.current.srcObject) {
          const stream = videoRef.current.srcObject as MediaStream;

          stream.getTracks().forEach((track) => {
            track.stop();
            track.enabled = false;
          });
          videoRef.current.srcObject = null;
        }

        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        setRecording(false);
        if (!recordedChunks.current) {
          return;
        }

        try {
          const formData = new FormData();
          formData.append('file', recordedChunks.current, `${uuidv4()}.webm`);
          setShowUploadModal(true);
          axios
            ?.request({
              url: `${process.env.REACT_APP_CHAT_API}/upload/${sessionId}`,
              method: 'POST',
              data: formData,
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / (progressEvent.total || 0),
                );
                setUploadProgress(percentCompleted);
              },
            })
            .then((rs: any) => {
              // Handle successful upload
              setShowUploadModal(false);
              if (rs?.error) {
                toast.error(`Video not uploaded, ${rs.error}`, {
                  position: 'top-center',
                });
                return;
              }
              toast.success('Video uploaded successfully', {
                position: 'top-center',
              });
            })
            .catch((e) => {
              console.error(e);
              toast.error(`Video not uploaded, ${e.toString()}`, {
                position: 'top-center',
              });
              // Handle error
            })
            .finally(() => {
              // Reset progress and hide modal regardless of success or error
              setUploadProgress(0);
              setShowUploadModal(false);
              onUploaded && onUploaded();
            });
        } catch (e) {
          console.error(e);
        }
      };

      mediaRecorderRef.current.start();

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setRecording(true);
    },
    [axios],
  );

  const handleDataAvailable = useCallback((event: BlobEvent) => {
    if (event.data.size > 0) {
      recordedChunks.current = event.data;
    }
  }, []);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current?.stop();
    }
  }, []);

  return { recording, handleStartRecording, stopRecording, uploadProgress, showUploadModal };
};

export default useVideoRecord;
