import { useCallback } from 'react';

import useAxios from '../../axios/useAxios';
import { DialogueDto, DialogueHook } from './useDialogue.types';

const useDialogue = (
  isLoading?: boolean,
  setIsLoading?: (newState: boolean) => void,
): DialogueHook => {
  const axios = useAxios();

  const postDialog = useCallback(
    async (payload: DialogueDto) => {
      if (!payload.sessionId || !payload.text) {
        return;
      }
      try {
        setIsLoading && setIsLoading(true);
        await axios.request({
          url: `${process.env.REACT_APP_CHAT_API}/dialogues`,
          method: 'POST',
          data: payload,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading && setIsLoading(false);
      }
    },
    [axios],
  );
  return {
    postDialog,
  };
};

export default useDialogue;
