import React from 'react';
import { toast } from 'react-toastify';

const CopyCell: React.FC<any> = (props) => {
  const {
    row,
    column: { id },
  } = props;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(row.values[id] || 'no link');
      toast.success('Link copied to clipboard', {
        position: 'top-center',
      });
    } catch (e: any) {
      toast.error(`An error happened during copying, ${e.toString()}`, {
        position: 'top-center',
      });
    }
  };

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        copyToClipboard();
      }}
      className='cursor-copy text-blue-600 hover:text-blue-800 visited:text-purple-600 disabled:text-gray-400 disabled:cursor-not-allowed'
      disabled={!row.values[id]}
    >
      Copy
    </button>
  );
};

export default CopyCell;
