import React from 'react';
import { TableOptions, useTable } from 'react-table';
import { usePagination } from '../../services/hooks/pagination/usePagination';
import Pagination from '../pagination/Pagination';
import { Spinner } from '../spinner/Spinner';

interface TableProps<T extends object = any> extends TableOptions<T> {
  totalRecords: number;
  rowsPerPage?: number;
  fetchFunction: (params: any) => void;
  isLoading: boolean;
}

export const Table = <T extends object = any>(props: TableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>({
    ...props,
  });

  const { totalRecords, fetchFunction, isLoading, rowsPerPage = 10 } = props;

  const paginationProps = usePagination(totalRecords, rowsPerPage, fetchFunction);

  return (
    <>
      <div className='overflow-x-auto'>
        <table
          {...getTableProps()}
          className='min-w-full divide-y divide-gray-300 border-collapse border border-gray-200'
        >
          <thead className='bg-blue-100'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className='px-6 py-3 text-left text-sm font-semibold text-blue-700 uppercase tracking-wider border-b border-gray-300'
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className='bg-white'>
            {isLoading ? (
              <tr>
                <td colSpan={1000} className='p-10 text-center'>
                  <Spinner />
                </td>
              </tr>
            ) : rows.length > 0 ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className='hover:bg-blue-50'>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className='px-6 py-4 whitespace-nowrap text-sm text-gray-700 border-b border-gray-300'
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={1000} className='p-4 text-center text-gray-600'>
                  No records available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalRecords > rowsPerPage ? <Pagination {...paginationProps} /> : null}
    </>
  );
};
