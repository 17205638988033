import React, { useEffect } from 'react';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import useSession from '../../services/hooks/session/useSession';
import { SessionDTO } from '../../services/hooks/session/useSession.types';
import {
  IAudioEmotion,
  IBlinkDetection,
  IFacialEmotion,
  ITextEmotion,
} from '../../services/hooks/session/emotions.interface';
import { toast } from 'react-toastify';
import { AudioFeaturesAnalysisObject } from '../../components/audio-features/index.interface';
import DataVisualizer from '../../components/audio-features';
import classNames from 'classnames';
import { Refresh } from '../../assets/svg/refresh';
import { useKeycloak } from '@react-keycloak/web';

// Componenta pentru player video
const VideoPlayer = ({ src }: { src: string }) => {
  return (
    <video className='w-full' controls>
      <source src={src} type='video/mp4' />
      Your browser does not support the video tag.
    </video>
  );
};

const BlinkDetectionsGraph = ({ data, title }: { data: IBlinkDetection[]; title: string }) => {
  if (!data.length) {
    return null;
  }
  const config = {
    chart: {
      type: 'spline',
    },
    title: {
      text: title,
    },
    xAxis: {
      title: {
        text: 'Time',
      },
    },
    yAxis: {
      title: {
        text: 'Blink count',
      },
    },

    series: [
      {
        name: 'Blink Detection',
        data: data.map((item) => [item.data.FrameTime, item.data.BlinkDetection]),
        type: 'spline',
      },
      {
        name: 'Blink Count',
        data: data.map((item) => [item.data.FrameTime, item.data.BlinkCount]),
        type: 'spline',
      },
    ],
  };
  return (
    <div className='p-4 border border-gray-200 rounded shadow'>
      <HighchartsReact highcharts={Highcharts} options={config} callback={() => console.log} />
    </div>
  );
};

const AudioAnalysisGraph = ({ data, title }: { data: IAudioEmotion[]; title: string }) => {
  if (!data.length) {
    return null;
  }
  const config = {
    chart: {
      type: 'spline',
    },
    title: {
      text: title,
    },
    xAxis: {
      title: {
        text: 'Original Time',
      },
    },
    yAxis: {
      title: {
        text: 'Emotion Intensity',
      },
    },

    series: [
      {
        name: 'Surprised',
        data: data.map((item) => [item.data.Orig_Time, item.data.surprised]),
        type: 'spline',
      },
      {
        name: 'Neutral',
        data: data.map((item) => [item.data.Orig_Time, item.data.neutral]),
        type: 'spline',
      },
      {
        name: 'Happy',
        data: data.map((item) => [item.data.Orig_Time, item.data.happy]),
        type: 'spline',
      },
      {
        name: 'Sad',
        data: data.map((item) => [item.data.Orig_Time, item.data.sad]),
        type: 'spline',
      },
      {
        name: 'Angry',
        data: data.map((item) => [item.data.Orig_Time, item.data.angry]),
        type: 'spline',
      },
      {
        name: 'Calm',
        data: data.map((item) => [item.data.Orig_Time, item.data.calm]),
        type: 'spline',
      },
      {
        name: 'Fearful',
        data: data.map((item) => [item.data.Orig_Time, item.data.fearful]),
        type: 'spline',
      },
      {
        name: 'Disgust',
        data: data.map((item) => [item.data.Orig_Time, item.data.disgust]),
        type: 'spline',
      },
    ],
  };
  return (
    <div className='p-4 border border-gray-200 rounded shadow'>
      <HighchartsReact highcharts={Highcharts} options={config} callback={() => console.log} />
    </div>
  );
};

const FacialAnalysisGraph = ({ data, title }: { data: IFacialEmotion[]; title: string }) => {
  if (!data.length) {
    return null;
  }
  const config = {
    chart: {
      type: 'spline',
    },
    title: {
      text: title,
    },
    xAxis: {
      title: {
        text: 'Frame Time',
      },
    },
    yAxis: {
      title: {
        text: 'Emotion Intensity',
      },
    },

    series: [
      {
        name: 'Anger',
        data: data.map((item) => [item.data.frame_time, item.data.Anger]),
        type: 'spline',
      },
      {
        name: 'Arousal',
        data: data.map((item) => [item.data.frame_time, item.data.arousal]),
        type: 'spline',
      },
      {
        name: 'Disgust',
        data: data.map((item) => [item.data.frame_time, item.data.Disgust]),
        type: 'spline',
      },
      {
        name: 'Fear',
        data: data.map((item) => [item.data.frame_time, item.data.Fear]),
        type: 'spline',
      },
      {
        name: 'Happy',
        data: data.map((item) => [item.data.frame_time, item.data.Happy]),
        type: 'spline',
      },
      {
        name: 'Neutral',
        data: data.map((item) => [item.data.frame_time, item.data.Neutral]),
        type: 'spline',
      },
      {
        name: 'Sad',
        data: data.map((item) => [item.data.frame_time, item.data.Sad]),
        type: 'spline',
      },
      {
        name: 'Surprise',
        data: data.map((item) => [item.data.frame_time, item.data.Surprise]),
        type: 'spline',
      },
      {
        name: 'Contempt',
        data: data.map((item) => [item.data.frame_time, item.data.Contempt]),
        type: 'spline',
      },
    ],
  };
  return (
    <div className='p-4 border border-gray-200 rounded shadow'>
      <HighchartsReact highcharts={Highcharts} options={config} callback={() => console.log} />
    </div>
  );
};

const TextAnalysisGraph = ({ data, title }: { data: ITextEmotion[]; title: string }) => {
  if (!data.length) {
    return null;
  }
  const config = {
    chart: {
      type: 'spline',
    },
    title: {
      text: title,
    },
    xAxis: {
      title: {
        text: 'Original Time',
      },
    },
    yAxis: {
      title: {
        text: 'Emotion Intensity',
      },
    },

    series: [
      {
        name: 'Angry',
        data: data.map((item) => [item.data.Orig_Time, item.data.angry]),
        type: 'spline',
      },
      {
        name: 'Excited',
        data: data.map((item) => [item.data.Orig_Time, item.data.excited]),
        type: 'spline',
      },
      {
        name: 'Happy',
        data: data.map((item) => [item.data.Orig_Time, item.data.happy]),
        type: 'spline',
      },
      {
        name: 'Neutral',
        data: data.map((item) => [item.data.Orig_Time, item.data.neutral]),
        type: 'spline',
      },
      {
        name: 'Sad',
        data: data.map((item) => [item.data.Orig_Time, item.data.sad]),
        type: 'spline',
      },
      {
        name: 'Surprise',
        data: data.map((item) => [item.data.Orig_Time, item.data.surprise]),
        type: 'spline',
      },
    ],
  };
  return (
    <div className='p-4 border border-gray-200 rounded shadow'>
      <HighchartsReact highcharts={Highcharts} options={config} callback={() => console.log} />
    </div>
  );
};

// Pagina de detalii sesiune
const SessionDetailsPage = () => {
  const { keycloak } = useKeycloak();

  const [session, setSession] = useState<SessionDTO | null>(null);
  const [facialData, setFacialData] = useState<IFacialEmotion[]>([]);
  const [audioData, setAudioData] = useState<IAudioEmotion[]>([]);
  const [textualData, setTextualData] = useState<ITextEmotion[]>([]);
  const [blinkDetection, setBlinkDetection] = useState<IBlinkDetection[]>([]);
  const [audioFeaturesData, setAudioFeaturesData] = useState<AudioFeaturesAnalysisObject | null>(
    null,
  );

  const { sessionId } = useParams();
  const {
    getSession,
    processSession,
    getFacialEmotions,
    getAudioEmotions,
    getTextEmotions,
    getAudioTextFeatures,
    getBlinkDetection,
  } = useSession(false, () => {});

  const getCurrentSession = async (sessionId: string) => {
    const ses = await getSession(sessionId);
    const [f, a, t, at, b] = await Promise.all([
      getFacialEmotions(sessionId),
      getAudioEmotions(sessionId),
      getTextEmotions(sessionId),
      getAudioTextFeatures(sessionId),
      getBlinkDetection(sessionId),
    ]);
    setFacialData(f?.items || []);
    setAudioData(a?.items || []);
    setTextualData(t?.items || []);
    setBlinkDetection(b?.items || []);
    if (at?.items?.length) {
      setAudioFeaturesData(at.items[0].data);
    }
    setSession(ses);
  };
  useEffect(() => {
    if (!keycloak.authenticated) {
      return;
    }
    getCurrentSession(sessionId!);
  }, [sessionId, keycloak.authenticated]);
  const startAnalysis = (target: string) => {
    return async () => {
      if (!session) {
        return;
      }
      await processSession({
        sessionId: session.sessionId,
        userId: session.userId,
        target,
      });
      toast.success(`'${target}' model started processing`, {
        position: 'top-center',
      });
    };
  };

  return (
    <div className='container mx-auto p-4'>
      <h1 className='text-2xl font-bold mb-4'>Session Details</h1>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
        <div className='col'>
          {session?.recordingLink && <VideoPlayer src={session?.recordingLink} />}
        </div>

        <div className='flex flex-col col-span-2'>
          <div>
            <b>Session Id:</b> {session?.sessionId}
          </div>
          <div>
            <b>User Id:</b> {session?.userId}
          </div>
          {/*<div>*/}
          {/*  <b>Author:</b> {session?.name}*/}
          {/*</div>*/}
          <div>
            <b>Data:</b> {dayjs(new Date(session?.recordedDate || '')).format('MM-DD-YYYY HH:mm')}
          </div>
          <div>
            <b>Tags:</b> {session?.comment}
          </div>
          <hr className='separator' />
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex flex-col'>
              <button
                className={classNames(
                  'mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-nowrap flex justify-between',
                  {
                    'bg-green-500 hover:bg-green-700': facialData?.length,
                  },
                )}
                onClick={startAnalysis('facial')}
              >
                Run Facial 1{!!facialData?.length && <Refresh />}
              </button>
              <button
                className={classNames(
                  'mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-nowrap flex justify-between',
                  {
                    'bg-green-500 hover:bg-green-700': audioData?.length,
                  },
                )}
                onClick={startAnalysis('audio')}
              >
                Run Audio 1{!!audioData?.length && <Refresh />}
              </button>
              <button
                className={classNames(
                  'mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-nowrap flex justify-between',
                  {
                    'bg-green-500 hover:bg-green-700': textualData?.length,
                  },
                )}
                onClick={startAnalysis('text')}
              >
                Run Textual 1{!!textualData?.length && <Refresh />}
              </button>
            </div>
            <div className='flex flex-col'>
              <button
                className={classNames(
                  'mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-nowrap flex justify-between',
                  {
                    'bg-green-500 hover:bg-green-700': audioFeaturesData,
                  },
                )}
                onClick={startAnalysis('audio_text_features')}
              >
                Run Audio Features{!!audioFeaturesData && <Refresh />}
              </button>
              <button
                className={classNames(
                  'mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-nowrap flex justify-between',
                  {
                    'bg-green-500 hover:bg-green-700': blinkDetection?.length,
                  },
                )}
                onClick={startAnalysis('blink_detection')}
              >
                Run Blink Detection{!!blinkDetection?.length && <Refresh />}
              </button>
              <button
                className='mb-2 bg-gray-600 text-white font-bold py-2 px-4 rounded cursor-not-allowed flex-nowrap flex justify-between'
                onClick={startAnalysis('text')}
                disabled
              >
                Run Textual 2
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className='separator' />
      {audioFeaturesData && (
        <>
          <DataVisualizer {...audioFeaturesData} />
          <hr className='separator' />
        </>
      )}
      <div className='grid grid-cols-1 gap-4'>
        {facialData && <FacialAnalysisGraph data={facialData} title='Facial Analysis Model 1' />}
        {audioData && <AudioAnalysisGraph data={audioData} title='Audio Analysis Model 1' />}
        {textualData && <TextAnalysisGraph data={textualData} title='Textual Analysis Model 1' />}
        {blinkDetection && <BlinkDetectionsGraph data={blinkDetection} title='Blink detection' />}
      </div>
    </div>
  );
};

export default SessionDetailsPage;
