import React, { useCallback, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import EventModal from '../../components/event-modal/eventModal';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';
import { useSearchParams } from 'react-router-dom';
import useAxios from '../../services/axios/useAxios';
import useMeeting from '../../services/hooks/meeting/useMeeting';
import useZoom from '../../services/hooks/zoom/useZoom';
import { MeetingDto } from '../../services/hooks/meeting/useMeeting.types';
import { useParams } from 'react-router';

dayjs.extend(duration);

const Calendar = () => {
  const [meetings, setMeetings] = useState<EventInput[]>();
  const [showEventDialog, setShowEventDialog] = useState(false);
  const [role, setRole] = useState('patient');
  const [selected, setSelected] = useState<DateSelectArg>();
  const [event, setEvent] = useState<EventClickArg>();
  const { keycloak } = useKeycloak();
  const { getMeetings } = useMeeting();

  useEffect(() => {
    if (!keycloak?.authenticated) {
      return;
    }
    keycloak?.loadUserProfile().then(async (user: any) => {
      const m = await getMeetings(user.id);
      const g = getInitialEvents(m || []);
      setMeetings(g);
    });
    const isManager = keycloak.hasRealmRole('meetings-manager');
    if (isManager) {
      setRole('manager');
    }
  }, [keycloak?.authenticated]);

  const handleEventClick = (arg: EventClickArg) => {
    setEvent(arg);
    setSelected(undefined);
    setShowEventDialog(true);
  };
  const handleOnCancel = () => {
    setSelected(undefined);
    setShowEventDialog(false);
  };

  const getInitialEvents = useCallback((m: MeetingDto[]): EventInput[] => {
    if (!m) {
      return [];
    }
    return m.map((meeting) => {
      const event: EventInput = {
        id: meeting.id,
        title: meeting.name,
        start: meeting.startsAt,
        end: meeting.endsAt,
        extendedProps: {
          guests: meeting.guests,
          zoomId: meeting.zoomId,
        },
      };
      if (meeting.isRecurring) {
        event.rrule = {
          freq: meeting.recurrencePattern?.freq,
          interval: meeting.recurrencePattern?.interval,
          dtstart: meeting.recurrencePattern?.dtstart,
          until: meeting.recurrencePattern?.until,
          byweekday: meeting.recurrencePattern?.byweekday,
        };
        event.duration = dayjs
          .duration(dayjs(meeting.endsAt).diff(dayjs(meeting.startsAt)))
          .format('HH:mm');
      }
      return event;
    });
  }, []);

  return (
    <div className=''>
      <div className='flex-1 p-4'>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, rrulePlugin]}
          initialView='timeGridWeek'
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          eventClick={handleEventClick}
          dayMaxEvents={true}
          events={meetings}
        />
      </div>
      {showEventDialog && (
        <EventModal
          dateSelected={selected}
          eventSelected={event}
          handleOnCancel={handleOnCancel}
          readonly={true}
        />
      )}
    </div>
  );
};
export default Calendar;
