import React, { ReactElement, useEffect } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

interface GuardProps {
  children: ReactElement<any, any>;
}

export const ManagerGuardedRoute: React.FC<GuardProps> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!keycloak) {
      return;
    }
    const isManager = keycloak.hasRealmRole('meetings-manager');

    localStorage.setItem('lastManagerPath', location.pathname);
    localStorage.removeItem('lastPhysicianPath');
    localStorage.removeItem('lastPatientPath');
    localStorage.removeItem('lastAdminPath');
    keycloak.onReady = (authenticated) => {
      if (!authenticated && !isManager) {
        navigate('/');
      }
    };
  }, [keycloak]);
  return <>{children}</>;
};
