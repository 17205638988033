import * as React from 'react';
const StopMicrophone: React.FC<any> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={800}
    height={800}
    viewBox='0 0 512 512'
    className='h-5 w-5'
    {...props}
  >
    <path d='M439.5 236c0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 70-64 126.9-142.7 126.9-78.7 0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 86.2 71.5 157.4 163.1 166.7v57.5H212c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4h88c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z' />
    <path d='M256 323.5c51 0 92.3-41.3 92.3-92.3V103.3c0-51-41.3-92.3-92.3-92.3s-92.3 41.3-92.3 92.3v127.9c0 51 41.3 92.3 92.3 92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3 23.5 52.3 52.3v127.9c0 28.8-23.5 52.3-52.3 52.3s-52.3-23.5-52.3-52.3V103.3z' />
  </svg>
);
export default StopMicrophone;
