import React from 'react';
import './toggle.scss';

interface IToggleProps {
  label: string;
  onChange: (flag: boolean) => any;
  value: boolean;
  errorMessage?: string;
}

const Toggle: React.FC<IToggleProps> = ({ label, value, onChange, errorMessage }) => {
  return (
    <div className='toggle-container'>
      <span className='toggle-label'>{label}</span>
      <label className='toggle-switch'>
        <input type='checkbox' checked={value} onChange={() => onChange(!value)} />
        <span className='toggle-slider'></span>
      </label>
      {errorMessage && <div className='error-tooltip'>{errorMessage}</div>}
    </div>
  );
};

export default Toggle;
