import React from 'react';
import { AudioFeaturesAnalysisObject } from './index.interface';

const DataVisualizer: React.FC<AudioFeaturesAnalysisObject> = (audioFeaturesAnalysis) => {
  return (
    <div className='p-4 bg-white shadow-lg rounded-lg'>
      <div className='grid grid-cols-2 gap-4'>
        {/* Basic Audio Characteristics */}
        <div className='grid grid-cols-1 gap-4'>
          <div className='grid grid-cols-2 gap-4'>
            <div className='p-4 border border-gray-200 rounded shadow'>
              <h2 className='text-lg font-semibold'>Basic Audio Characteristics</h2>
              <ul className='list-disc pl-5'>
                <li>
                  Duration: {audioFeaturesAnalysis.audio_data.basic_audio_characteristics.duration}{' '}
                  seconds
                </li>
                <li>
                  Channels: {audioFeaturesAnalysis.audio_data.basic_audio_characteristics.channels}
                </li>
                <li>
                  Frame Rate:{' '}
                  {audioFeaturesAnalysis.audio_data.basic_audio_characteristics.frame_rate}
                </li>
                <li>
                  Number of Frames:{' '}
                  {audioFeaturesAnalysis.audio_data.basic_audio_characteristics.num_frames}
                </li>
                <li>
                  Frame Width:{' '}
                  {audioFeaturesAnalysis.audio_data.basic_audio_characteristics.frame_width}
                </li>
                <li>
                  Number of Samples:{' '}
                  {audioFeaturesAnalysis.audio_data.basic_audio_characteristics.num_samples}
                </li>
                <li>
                  Sample Width:{' '}
                  {audioFeaturesAnalysis.audio_data.basic_audio_characteristics.sample_width}
                </li>
              </ul>
            </div>
            <div className='grid grid-cols-1 gap-4'>
              {/* Average Speaking Rate */}
              <div className='p-4 border border-gray-200 rounded shadow'>
                <h2 className='text-lg font-semibold'>Average Speaking Rate</h2>
                <p>{audioFeaturesAnalysis.audio_data.avg_speaking_rate.avg_speak_rate} WPM</p>
              </div>
              {/*Sentiment Analysis and Keywords */}
              <div className='p-4 border border-gray-200 rounded shadow'>
                <h2 className='text-lg font-semibold'>Sentiment Analysis</h2>
                <div className=''>
                  <div>Positive: {audioFeaturesAnalysis.text_data['Positivity %']}%</div>
                  <div>Neutral: {audioFeaturesAnalysis.text_data['Neutral %']}%</div>
                  <div>Negative: {audioFeaturesAnalysis.text_data['Negitivity %']}%</div>
                </div>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-4'>
            {/* DBFS Info */}
            <div className='p-4 border border-gray-200 rounded shadow'>
              <h2 className='text-lg font-semibold'>DBFS Info</h2>
              <ul className='list-disc pl-5'>
                {audioFeaturesAnalysis.audio_data.dbfs_info.dbfs_values.map((value, index) => (
                  <li key={`dbfs-${index}`}>Value: {value}</li>
                ))}
              </ul>
            </div>

            {/* RMS Info */}
            <div className='p-4 border border-gray-200 rounded shadow'>
              <h2 className='text-lg font-semibold'>RMS Info</h2>
              <ul className='list-disc pl-5'>
                {audioFeaturesAnalysis.audio_data.rms_info.rms_values.map((value, index) => (
                  <li key={`rms-${index}`}>Value: {value}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div className='col-span-2 p-4 border border-gray-200 rounded shadow'>
            <h2 className='text-lg font-semibold'>Keywords Summary</h2>
            <div className='flex flex-wrap gap-2'>
              {audioFeaturesAnalysis.text_data['Keywords summary']?.map((keyword, index) => (
                <span key={index} className='px-2 py-1 bg-gray-200 rounded'>
                  {keyword}
                </span>
              ))}
            </div>
          </div>
          <div className='p-4 border border-gray-200 rounded shadow'>
            <h2 className='text-lg font-semibold'>Course words list</h2>
            <div className='flex flex-wrap gap-2'>
              {audioFeaturesAnalysis.text_data.curse_words_list?.map((keyword, index) => (
                <span key={index} className='px-2 py-1 bg-gray-200 rounded'>
                  {keyword}
                </span>
              ))}
            </div>
          </div>
          <div className='p-4 border border-gray-200 rounded shadow'>
            <h2 className='text-lg font-semibold'>Negative phrase list</h2>
            <div className='flex flex-wrap gap-2'>
              {audioFeaturesAnalysis.text_data.negative_phrase_list?.map((keyword, index) => (
                <span key={index} className='px-2 py-1 bg-gray-200 rounded'>
                  {keyword}
                </span>
              ))}
            </div>
          </div>
          <div className='p-4 border border-gray-200 rounded shadow'>
            <h2 className='text-lg font-semibold'>Positive phrase list</h2>
            <div className='flex flex-wrap gap-2'>
              {audioFeaturesAnalysis.text_data.positive_phrase_list?.map((keyword, index) => (
                <span key={index} className='px-2 py-1 bg-gray-200 rounded'>
                  {keyword}
                </span>
              ))}
            </div>
          </div>
          <div className='p-4 border border-gray-200 rounded shadow'>
            <h2 className='text-lg font-semibold'>Negative sentences</h2>
            <div className='flex flex-wrap gap-2'>
              {audioFeaturesAnalysis.text_data.negative_sentences?.map((keyword, index) => (
                <span key={index} className='px-2 py-1 bg-gray-200 rounded'>
                  {keyword}
                </span>
              ))}
            </div>
          </div>
          <div className='p-4 border border-gray-200 rounded shadow'>
            <h2 className='text-lg font-semibold'>Positive sentences</h2>
            <div className='flex flex-wrap gap-2'>
              {audioFeaturesAnalysis.text_data.positive_sentences?.map((keyword, index) => (
                <span key={index} className='px-2 py-1 bg-gray-200 rounded'>
                  {keyword}
                </span>
              ))}
            </div>
          </div>
          <div className='p-4 border border-gray-200 rounded shadow'>
            <h2 className='text-lg font-semibold'>Neutral sentences</h2>
            <div className='flex flex-wrap gap-2'>
              {audioFeaturesAnalysis.text_data.neutral_sentences?.map((keyword, index) => (
                <span key={index} className='px-2 py-1 bg-gray-200 rounded'>
                  {keyword}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataVisualizer;
