import React, { useCallback, useState } from 'react';
import { Column } from 'react-table';

import { Table } from '../../components/table/Table';
import { ViewActions } from './view-actions/ViewActions';

import useAxios from '../../services/axios/useAxios';
import { NewZoomActions } from './new-zoom-actions/NewZoomActions';
import { useKeycloak } from '@react-keycloak/web';

export interface UsersResponse {
  totalRecords: number;
  users: User[];
}

interface User {
  userId: string;
  email: string;
  fullName: string;
  username: string;
}

const columns: Column<User>[] = [
  {
    Header: 'User ID',
    accessor: 'userId',
  },
  {
    Header: 'Name',
    accessor: 'fullName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: '',
    id: 'ViewActions',

    Cell: ({ row }: any) => (
      <div>
        <ViewActions userId={row.values.userId} />
      </div>
    ),
  },
];

export default function Users() {
  const [usersData, setUsersData] = useState<User[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setIsLoading] = useState<boolean>(false);
  const axios = useAxios();
  const {
    keycloak: { authenticated },
  } = useKeycloak();

  const fetchData = useCallback(
    async (params?: any) => {
      if (!authenticated) return;
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams();

        const limit = params?.rowsPerPage || 10;
        const page = params?.page || 0;
        queryParams.append('limit', limit);
        queryParams.append('skip', ((page - 1) * limit).toString());
        // queryParams.append('search', params?.search);

        const response: UsersResponse = await axios.request({
          method: 'GET',
          url: `${process.env.REACT_APP_CHAT_API}/users?${queryParams.toString()}`,
        });

        setUsersData(response.users);
        setTotalRecords(response.totalRecords);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, authenticated],
  );

  return (
    <Table
      columns={columns}
      data={usersData}
      totalRecords={totalRecords}
      fetchFunction={fetchData}
      isLoading={loading}
    />
  );
}
