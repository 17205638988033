import { useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import AxiosFacade from './AxiosFacade';
import { AxiosRequestConfig } from 'axios';
import { useNavigate } from 'react-router-dom';

const useAxios = (options?: AxiosRequestConfig) => {
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const navigate = useNavigate();

  keycloak
    .updateToken(3600)
    .then(function (refreshed) {
      if (refreshed) {
        console.log('Token was successfully refreshed');
      }
    })
    .catch(async (err) => {
      // navigate('/');
    });

  return useMemo(() => {
    const axiosFacade = new AxiosFacade({
      ...options,
      headers: {
        ...(options?.headers || {}),
        Authorization: `Bearer ${token}`,
      },
    });

    axiosFacade.setResponseInterceptor(
      (response) => response,
      async (error) => {
        if (error.response?.status === 401) {
          await keycloak.login();
        }
      },
    );

    return axiosFacade;
  }, [keycloak, token, options]);
};

export default useAxios;
