import React from 'react';
import Modal from 'react-modal';

interface VideoModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  videoLink: string;
  title: string;
}

Modal.setAppElement('#root');

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onRequestClose, videoLink, title }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      className='flex flex-col gap-4 absolute top-1/2 left-1/2 max-w-4xl w-full transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-xl border border-gray-200'
      overlayClassName='fixed inset-0 bg-gray-900 bg-opacity-70 z-50'
    >
      <div className='flex justify-between items-center'>
        <span className='text-2xl font-medium text-blue-700 flex-1 text-center'>{title}</span>
        <button
          onClick={onRequestClose}
          className='text-2xl w-10 h-10 flex items-center justify-center font-medium text-blue-700 hover:text-blue-800 rounded-full hover:bg-blue-100 transition duration-300 ease-in-out'
          aria-label='Close'
        >
          &times;
        </button>
      </div>
      <div className='flex justify-center items-center'>
        <video width='100%' height='100%' controls>
          <source src={videoLink} />
          Your browser does not support the video tag.
        </video>
      </div>
    </Modal>
  );
};

export default VideoModal;
