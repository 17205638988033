import React, { useCallback } from 'react';
import clsx from 'clsx';

import LoadingDots from '../loading-dots/LoadingDots';

import { ChatLogProps } from './ChatLog.types';
import { capitalizeFirstLetter } from './ChatLog.utils';
import { ChatLogType } from '../../pages/chat/Chat.types';

import useSettings from '../../services/hooks/settings/useSettings';

import styles from './chatLog.module.scss';

export const ChatLog: React.FC<ChatLogProps> = ({ chatLog, isLoading }) => {
  const { settings } = useSettings();
  const { showPatientText, showChatText } = settings;

  const displayText = useCallback(
    ({ sender, message }: ChatLogType): string => {
      if (sender === 'User' && showPatientText) {
        return capitalizeFirstLetter(message);
      }
      if (sender === 'Bot' && showChatText) {
        return capitalizeFirstLetter(message);
      }
      return '';
    },
    [showPatientText, showChatText],
  );

  return (
    <div className='mx-auto flex w-full flex-1 flex-col text-small gap-2 overflow-y-auto'>
      {(chatLog?.length === 0 || isLoading) && <LoadingDots />}
      {chatLog.map((chat) => (
        <div key={chat.id}>
          <span
            className={clsx(
              styles.smoothText,
              chat?.sender === 'User' ? 'text-spanish-grey' : 'text-ph-green',
            )}
          >
            {displayText(chat)}
          </span>
        </div>
      ))}
    </div>
  );
};
