import React, { FC, PropsWithChildren, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './Sidebar.scss';
import { useKeycloak } from '@react-keycloak/web';
import classNames from 'classnames';

export interface SidebarRoute {
  separator?: boolean;
  separatorLabel?: string;
  label?: string;
  path?: string;
}

export interface SidebarProps extends PropsWithChildren {
  routes: SidebarRoute[];
  color?: string;
}

const Sidebar: FC<SidebarProps> = ({ children, routes, color }) => {
  const { keycloak } = useKeycloak();

  const userInfo = useMemo(() => keycloak.tokenParsed, [keycloak.tokenParsed]);

  return (
    <nav
      className={classNames('flex flex-col gap-2 w-64 p-4 sidebar-container min-h-screen', {
        'bg-cyan-50': color === 'cyan',
        'bg-green-50': color === 'green',
        'bg-gray-50': !color,
      })}
    >
      <span className='text-center font-semibold'>{userInfo?.name}</span>
      {routes.map((route, index) => {
        if (route.separator) {
          return (
            <div key={`separator-${index}`} className='separator'>
              {route.separatorLabel && <span>{route.separatorLabel}</span>}
            </div>
          );
        } else if (route.path) {
          return (
            <NavLink
              key={route.path}
              to={route.path}
              end
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              aria-label={route.label}
            >
              {route.label}
            </NavLink>
          );
        }
        return null;
      })}
      <div className='bottom-group'>
        {/*{keycloak.hasRealmRole('patient') && (*/}
        {/*  <button onClick={() => navigate('/patient')}>Login as patient</button>*/}
        {/*)}*/}
        <button className='filled' onClick={() => keycloak.logout()}>
          Log out
        </button>
      </div>
    </nav>
  );
};

export default Sidebar;
