import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const ViewActions: React.FC<any> = ({ userId }) => {
  const navigate = useNavigate();

  const handleViewSessions = useCallback(() => {
    navigate(`/users/${userId}/sessions`);
  }, [navigate, userId]);

  return (
    <button
      onClick={handleViewSessions}
      className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
    >
      View Sessions
    </button>
  );
};
