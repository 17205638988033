const env = {
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI || 'https://chat.primaface.com',
  keicloackClientId: process.env.REACT_APP_SSO_CLIENT_ID || 'chat-app',
  keicloackUrl: process.env.REACT_APP_SSO_URL || 'https://keycloak.primaface.com',
  keicloackRealm: process.env.REACT_APP_SSO_REALM || 'CHAT',
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.env = env;

export default env;
