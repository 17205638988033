import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

// SERVICES
import useAxios from '../../services/axios/useAxios';

// ASSETS
import StartMicrophone from '../../assets/images/StartMicrophone';
import StopMicrophone from '../../assets/images/StopMicrophone';

// TYPES
import { VoiceRecorderProps } from './VoiceRecorder.types';

// UTILS
import { checkMicrophonePermissions } from './VoiceRecorder.utils';
import useDialogue from '../../services/hooks/dialogue/useDialogue';

export const VoiceRecorder: React.FC<VoiceRecorderProps> = ({
  setChatLog,
  response,
  sendMessage,
  sessionId,
  isLoading,
}) => {
  const isMicrophoneAvailable = useMemo(() => checkMicrophonePermissions(), []);
  const [isSpacebarHeld, setIsSpacebarHeld] = useState(false);
  const [speechStartedAt, setSpeechStartedAt] = useState<string>();

  const { transcript, listening, browserSupportsSpeechRecognition, resetTranscript } =
    useSpeechRecognition();

  const { postDialog } = useDialogue();

  const startListeningVoice = useCallback(() => {
    resetTranscript();
    if (!isMicrophoneAvailable) {
      console.error('microphone not available');
      return;
    }
    if (!browserSupportsSpeechRecognition) {
      console.error("browser doesn't support speech recognition");
      return;
    }
    setSpeechStartedAt(dayjs(new Date()).format());
    console.log('user start speaking');
    SpeechRecognition?.startListening({ continuous: true, language: 'en-US' });
  }, [isMicrophoneAvailable, browserSupportsSpeechRecognition, resetTranscript]);

  const stopListeningVoice = useCallback(async () => {
    if (transcript) {
      sendMessage(transcript);
      setChatLog((prev) => [{ sender: 'User', message: transcript, id: uuidv4() }, ...prev]);

      try {
        await postDialog({
          text: transcript,
          speaker: 'human',
          sessionId,
          speechStartedAt,
          speechEndedAt: dayjs(new Date()).format(),
        });
      } catch (e) {
        console.error(e);
      }
    }
    console.log('user stop speaking');
    SpeechRecognition?.stopListening();
  }, [transcript, sendMessage, response, sessionId, setChatLog, postDialog]);

  const recordVoice = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (isSpacebarHeld) {
      return;
    }

    if (!listening && sessionId) {
      startListeningVoice();
      return;
    }
    stopListeningVoice();
  }, [listening, sessionId, startListeningVoice, stopListeningVoice, isSpacebarHeld, isLoading]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isLoading) {
        return;
      }

      if (event.code === 'Space' && !listening && sessionId) {
        event.preventDefault();
        setIsSpacebarHeld(true);
        startListeningVoice();
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Space' && listening) {
        event.preventDefault();
        setIsSpacebarHeld(false);
        stopListeningVoice();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [listening, sessionId, startListeningVoice, stopListeningVoice, isLoading]);

  return (
    <div className='flex flex-col items-center'>
      <div className='flex gap-4'>
        <button
          className={`bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 ${
            listening ? 'bg-red-500 text-white' : 'bg-blue-500 text-white'
          }`}
          onClick={recordVoice}
          aria-label={listening ? 'Stop Recording' : 'Start Recording'}
          title={listening ? 'Stop Recording' : 'Start Recording'}
        >
          <div className='flex justify-between items-center gap-6 min-w-[300px]'>
            <span className='text-sm font-medium tracking-wide text-center'>
              {!listening ? 'Hold Space or Click to Speak' : 'Stop Listening'}
            </span>
            <span className='flex items-center text-sm'>
              {!listening ? <StartMicrophone /> : <StopMicrophone />}
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};
