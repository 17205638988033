export const checkTokenExpired = function (token: string): boolean {
  const payloadBase64 = token.split('.')[1];
  const payloadJson = atob(payloadBase64);
  const payload = JSON.parse(payloadJson);

  const exp = payload.exp;

  const currentTimestamp = Math.floor(Date.now() / 1000);

  return currentTimestamp > exp;
};
